
.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
}

.FilterAddWrap {
  float: right;
  margin-top: 20px;
  text-align: right;
}

.topstepperButons {
  margin-left: 0px !important;
  display: flex !important;
  flex-direction: row;
}

.topstepperButons button {
  border: 0px !important;
  padding: 3px 0px;
  margin: 0;
}

.FilterInput {
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.cancelButton {
  margin-left: 10px !important;
  display: inline-block !important;
}

.salText {
  font-weight: 600;
  margin-top: 5%;
}

.sal_levels {
  background-color: #d3d7dc;
  font-weight: 600;
  border-radius: 5%;
  padding: 10px 15px !important;
  display: inline-block;
  margin-top: 15px !important;
  margin-right: 15px !important;
  font-size: 12px;
}

.selected_level {
  background-color: white;
  border-radius: 5%;
  margin-top: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
}

.CompanySelect {
  color: unset !important;
}

.TabOptionsActive>span {
  background-color: #4b0079 !important;
}

.TabOptions button {
  border: 1px solid #4b0079 !important;
  border-radius: 10px !important;
  margin-right: 12% !important;
  color: #707070 !important;
  font-weight: 600 !important;
  background-color: white !important;
  padding: 10px !important;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 88%;
}

.CompanyFormHead {
  font-weight: 500;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 5px;
  display: block;
}

.secure_para {
  font-weight: 400;
  font-size: 12px;
}

.Security_Assurance_Grid {
  margin-bottom: 20px !important;
}

.Tabinner {
  border: 1px solid#4b0079 !important;
  padding: 20px;
  border-radius: 0px 0px 5px 5px;
}

.Tabinner h4 {
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 5px;
  margin-top: 20px;
}

.nextBtn {
  display: inline-flex !important;
  margin: 0px 10px 15px 10px;
  min-width: 83px;
  height: 31px;
  align-items: center;
}

.TabOptionsInactive p {
  background-color: #fcfdfd !important;
  color: #707070 !important;
}

.Companyrequirenos {
  font-size: 16px;
  font-weight: 600;
}

.Companyrequireblock {
  padding: 15px;
  background-color: #e1e1e1;
  border-radius: 10px;
  margin: 15px 0px;
}

.PiiDataSwitch,
.PiiDataSwitchCheck {
  width: 136px !important;
  padding: 4px !important;
  border: 1px solid #4b0079;
  border-radius: 20px;
  z-index: 2 !important;
  height: 39px !important;
}

.PiiDataSwitch>span,
.PiiDataSwitchCheck>span {
  padding: 4px;
}

.PiiDataSwitch>span {
  transform: translateX(40px) !important;
}

.PiiDataSwitch>span+span,
.PiiDataSwitchCheck>span+span {
  border-radius: 20px;
  background-color: transparent !important;
}

.PiiDataSwitch>span>span>span,
.PiiDataSwitchCheck>span>span>span {
  width: 70px;
  background-color: #4b0079;
  height: 28px;
  border-radius: 25px;
}

.PiiDataSwitchLabels {
  position: absolute;
  width: 116px;
  left: 0;
  top: 0;
}

.pgswitch {
  position: relative;
  margin: -10px 349px;
}

.PiiDataSwitchLabels>span {
  width: 58px;
  justify-content: center;
  font-weight: 500;
  display: inline-flex;
  padding: 12px 0px;
}

.PiiDataSwitchCheck+span .PiiDataSwitchNo,
.PiiDataSwitch+span .PiiDataSwitchYes {
  color: #fff;
}

.PiiDataSwitchCheck+span .PiiDataSwitchYes,
.PiiDataSwitch+span .PiiDataSwitchNo {
  color: #4b0079;
}

.PiiDataSwitchCheck>span>span>span::before {
  content: "Standard";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 6px 0px;
  justify-content: center;
}

.PiiDataSwitch>span>span>span::before {
  content: "Custom";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 5px 0px;
  justify-content: center;
}

.para {
  color: white;
  z-index: 1;
  margin: 1px;
}

.prevBtn {
  display: inline-flex !important;
  margin: 0px 10px 15px 0px;
  min-width: 113px;
  height: 31px;
  align-items: center;
}
.warningWrap>div {
  width: 100%;
  border-radius: 10px;
  margin-top: 15px;
}

.warningWrap>div>div>div {
  padding: 0px;
  align-items: center;
}

.warningWrap>div>div>svg {
  width: 2em;
  height: 1.5em;
  font-size: 1.2rem;
}

.warningWrap>div>div+div+div>button svg {
  width: 2em;
  height: 1.5em;
  font-size: 1.2rem;
}

.warningWrap>div>div+div {
  line-height: 1.5 !important;
  font-size: 16px;
  font-weight: 500 !important;
  padding: 0px;
  align-items: center;
  display: inline-flex;
}

.cancelButton button {
  border: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: transparent !important;
  color: #4b0079 !important;
  box-shadow: none !important;
}

.crossBtn {
  margin-right: 10px !important;
  min-width: 0px !important;
  float: right !important;
  position: relative;
}

.crossBtn button {
  min-width: 0px !important;
}

.crossBtn .cancleTooltip {
  visibility: hidden;
  width: 60px;
  color: #4b0079 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  left: -10px;
  top: -20px;
}

.crossBtn:hover .cancleTooltip {
  visibility: visible;
}

.TabOptionsWrap {
  display: inline-flex;
  width: 20%;
}

.firmTypeButton {
  display: flex;
  width: 100%;
}

.TabOptionsWrap>.TabOptions:first-child {
  display: inline-flex;
}

.TabOptionsWrap>.TabOptions:first-child,
.FieldsetControl,
.FieldsetControl>div {
  width: 100%;
}

.FieldsetControl>div label:first-child {
  margin-right: 5%;
}

.CompanyrequireblockWrap {
  display: flex;
  margin-bottom: 4px;
}

.Companyrequire {
  width: 200px;
}
.FooterGridWrap > div{
  display: flex;
  justify-content: space-between;
}