.TabOptions button {
  border: 1px solid #4b0079 !important;
  border-radius: 10px !important;
  margin-right: 4px !important;
  color: #707070 !important;
  font-weight: 600 !important;
  background-color: white !important;
  padding: 10px !important;
  margin-top: 0px;
  margin-bottom: 5px;
}
.TabOptions button.TabFlagOptionsInactive, .TabOptions button.TabFlagOptionsActive{
  border: 1px solid #4b0079 !important;
  border-radius: 10px !important;
  margin-right: 4px !important;
  font-weight: 600 !important;
  background-color: white !important;
  padding: 7px 10px !important;
  margin-top: 0px;
  margin-bottom: 5px;
}
.TabOptions button.TabFlagOptionsActive, .TabOptions button.TabFlagOptionsActive span, .TabOptions button.TabOptionsActive{
color: #fff !important;
background-color: #4b0079 !important;
}
.TabOptions button.TabFlagOptionsInactive, .TabOptions button.TabFlagOptionsInactive span{
  color: #707070 !important;
  }

.TabOptionsInactive, .TabFlagOptionsInactive {
  background-color: #fcfdfd !important;
  margin: 1px;
}

.CompanyFormHead {
  font-weight: 600;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
  display: block;
  margin: 0px 0px 5px 280px;
  color: #4b0079;
  border-bottom: 1px solid #4b0079;
}


.ComplianceAccordion {
  padding: 0px !important;
  margin: 0px 0px 10px 280px !important;
  border-radius: 12px !important;
  border: 1px solid #4b0079;
}
.ComplianceAccordion > div:first-child {
  min-height: 37px;
  flex-direction: row-reverse;
  background-color: #fcfaff;
  border-radius: 10px;
  padding: 0px 0.5em;
  border-bottom: 1px solid #4b0079;
}
.ComplianceAccordion > div:first-child > div {
  margin: 0px;
  padding: 0px;
}
.ComplianceAccordion > div:last-child {
  border: 0px;
}
.qtext, .qtext *, .qtext pre {
  font-size: 12px;
  line-height: 18px;
  color: #707070;
}
.qtext pre {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.TabButtons {
  text-align: right;
}

.heading {
  font-size: 14px !important;
  width: 100%;
  font-weight: 600 !important;
}
.qContainer {
  display: flex;
  align-items: center;
  flex-grow: unset !important;
  flex-basis: unset !important;
}
.qTabContainer {
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 992px) and (max-width:1024px) {
  body .qContainer {
    width: calc(100% - 180px);
    max-width: calc(100% - 180px) !important;
  }
  body .qTabContainer {
    display: flex;
    width: 180px;
    max-width: 180px !important;
  }
}
@media screen and (max-width:991px) {
  body .qContainer {
    width: 100%;
    max-width: 100% !important;
  }
  body .qTabContainer {
    width: 100%;
    max-width: 100% !important;
    border-bottom: 1px solid #4b0079;
    margin-bottom: 20px;
  }
}
.qno {
  background-color: #4b0079;
  border-radius: 10px;
  padding: 4px 10px;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  color: #fff;
  text-align: center;
  line-height: 1.5;
}

.expandButton {
  float: right;
}

.suppGuidance {
  height: 50px;
  margin: 10px 0px 0px 0px;
}

.crossBtn {
  margin-right: 10px !important;
  margin-bottom: 20px !important;
  min-width: 0px !important;
  float: right !important;
  position: relative;
}

.crossBtn button {
  min-width: 0px !important;
}

.crossBtn .cancleTooltip {
  visibility: hidden;
  width: 60px;
  color: #4b0079 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 15px;
  left: -10px;
  top: -20px;
}

.crossBtn:hover .cancleTooltip {
  visibility: visible;
}
.heading {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.heading span {
  font-weight: 500;
}
.paraFlag, .TabFlagOptionsInactive .TabOptionsInactiveFlag  {
  color: white;
  z-index: 1;
  margin: 1px;
  line-height: 1;
}
.paraFlag svg, .TabOptions button.TabOptionsActive p{
  color: #fff;
}
.TabFlagOptionsInactive svg { color: #707070;}
.TabFlagOptionsActive {
  display: flex;
}
.qIconContainer svg {
  width: 30px;
  height: 30px;
  padding: 0px 2px;
}
.para , .TabOptionsInactive {
  line-height: 1.75;
  margin: 1px;
}
.DialogBoxTitle {
  text-align: center;
  margin: 0px;
  padding: 16px !important;
  border-bottom: 1px solid #0051a8 !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02);
}

.DialogBoxTitle h1 {
  margin: 0px !important;
}

.DialogBoxContext p {
  color: #707070;
}
.DialogBoxContext {
  padding: 16px !important;
  overflow: auto;
}
.DialogBoxContext textarea {
  min-width: 200px;
  min-height: 120px;
}
.qIconContainer{
  display: flex;
  align-items: center;
  padding-top: 5px;
}
.tableStyle {
  min-width: 400px;
  max-width: 100%;
}
.MuiSvgIcon-root{
  font-size: 1.2rem;
}
.spanStyle{
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width:240px;
  margin-left: 15px;
  padding-top: 15px;
}

.tableStyle th{
  background-color: #ded0e9 !important;
}
button.buttonInActive{
  color:"blue";
}
.qContainerWrap {
  margin-bottom: 15px !important;
}
.qContainerWrap:last-child{
  margin-bottom: 0px !important;
}
.uploadFilePaper {
  margin-left: 280px;
  margin-bottom: 12px;
  margin-top: 12px;
}