.InPageSideMenu {
  float: left;
  width: 50px;
}

.ComplianceVersion fieldset {
  min-height: auto;
  padding: 5px;
  border-color: #4b0079 !important;
}

.ComplianceVersion::before {
  background-color: transparent !important;
}

.ComplianceVersion {
  margin-left: 5px;
  background-color: #4b0079;
  color: #fff !important;
}

.ComplianceVersion>div {
  min-height: auto;
  padding: 10px 37px 10px 15px !important;
}

.ComplianceVersion svg {
  color: #fff !important;
}

.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
}

.ContinueButton {

  background-color: white !important;
  border: solid 2px #303f9f !important;
  border-radius: 12px !important;
  margin-top: 100px;
  color: #303f9f !important;
}

.Margin {
  margin: 4px;
}

/*Switch*/

.PiiDataSwitch,
.PiiDataSwitchCheck {
  width: 100px !important;
  padding: 4px !important;
  border: 1px solid #4b0079;
  border-radius: 20px;
  z-index: 2 !important;
  height: 39px !important;
}

.PiiDataSwitch>span,
.PiiDataSwitchCheck>span {
  padding: 4px;
}

.PiiDataSwitch>span {
  transform: translateX(40px) !important;
}

.PiiDataSwitch>span+span,
.PiiDataSwitchCheck>span+span {
  border-radius: 20px;
  background-color: transparent !important;
}

.PiiDataSwitch>span>span>span,
.PiiDataSwitchCheck>span>span>span {
  width: 48px;
  background-color: #4b0079;
  height: 28px;
  border-radius: 25px;
}

.PiiDataSwitchLabels {
  position: absolute;
  width: 116px;
  left: 0;
  top: 0;
}

.pgswitch {
  position: relative;
  margin: 7px 0px;
}

.PiiDataSwitchLabels>span {
  width: 48px;
  justify-content: center;
  font-weight: 500;
  display: inline-flex;
  padding: 12px 0px;
}

.PiiDataSwitchCheck+span .PiiDataSwitchNo,
.PiiDataSwitch+span .PiiDataSwitchYes {
  color: #fff;
}

.PiiDataSwitchCheck+span .PiiDataSwitchYes,
.PiiDataSwitch+span .PiiDataSwitchNo {
  color: #4b0079;
}

.PiiDataSwitchCheck>span>span>span::before {
  content: "No";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 6px 0px;
  justify-content: center;
}

.PiiDataSwitch>span>span>span::before {
  content: "YES";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 5px 0px;
  justify-content: center;
}

.saveAndExitButton {
  margin-left: 555px !important;
}

.saveAndExitButton button {
  border: 0px !important;
  background-color: transparent !important;
  color: #4b0079 !important;
  box-shadow: none !important;
}

.prevBtn,
.nextBtn {
  display: inline-flex !important;
  align-items: flex-start;
  margin-right: 10px;
}

.topstepperButons {
  margin-left: 0px !important;
  display: flex !important;
  flex-direction: row;
}

.topstepperButons button {
  border: 0px !important;
  padding: 3px 0px;
  margin: 0;
}

.top_nav {
  display: flex;
  justify-content: space-between;
}

.prevBtn button,
.nextBtn button {
  border: 0px !important;
  display: block;
  white-space: nowrap;
}

.nextBtn {
  margin-bottom: 20px;
}

.saveAndExitBottom {
  display: flex !important;
  float: right;
}

.saveAndExitBottom button {
  padding-left: 15px !important;
  padding-right: 15px !important;
  background-color: transparent !important;
  color: #4b0079 !important;
  box-shadow: none !important;
}

.bottomPagination {
  margin-right: 50px;
}
.bottomPagination ul {
  margin: 3px 0px;
}
.FormHelperText {
  color: #e53935 !important;
  padding: 0px 0px 10px 0px !important;
  display: block !important;
}

.errormsg {
  display: inline-block !important;
  width: 100% !important;
}

.DialogBoxTitle {
  text-align: center;
  margin: 0px;
  padding: 16px !important;
  border-bottom: 1px solid #0051a8 !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02);
}

.DialogBoxTitle h1 {
  margin: 0px !important;
}

.DialogBoxContext p {
  color: #707070;
}

.DialogBoxContext {
  padding: 16px !important;
}

.OtherOfficeWrap legend {
  font-size: 14px;
}

.MainOfficeDialogboxPaper {
  height: 215px !important;
  border: 1px solid #000103 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
}

.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}

.refrenceDialogboxPaper, .allDocumentDialogboxPaper {
  height: 515px !important;
  border: 1px solid #000103 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
}


.refrenceDialogboxContainer, .allDocumentDialogboxContainer {
  max-width: 90%;
  min-width: 70% !important;
  margin: 0 auto;
}

.CompanyFormHead {
  font-weight: 500;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 5px;
  display: block;
}

.Qbank {
  padding: 15px 20px;
  margin-bottom: 20px;
  height: 1090px;
  min-height: 400px;
  background-color: #fff;
  border-bottom-left-radius: 20px;
}

.TabButtons {
  min-height: 1090px;
}

.ComplianceAccordion {
  padding: 25px;
  margin-bottom: 10px !important;
  border-radius: 12px !important;
  border: 1px solid #4b0079;
  clear: right;
}

.sideNavPanel {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 1090px;
  margin-top: 14px;
  margin-left: 20px;
  width: 250px;
  float: left;
  background-color: #fcfaff;
}

.sideNavPanel_inner {
  border-radius: 12px !important;
  border: 1px solid #4b0079;
  padding: 10px;
}


.sidePanelQuestionCount {
  font-weight: 400;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  padding-bottom: 10px;
  font-weight: bold;
  border-bottom: 1px solid #4b0079;
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}

.bottomPaginationButtonDiv {
  margin-top: 15px;
  display: flex !important;
  float: right;
}

.paginationDiv {
  display: inline-block !important;
}
.paginationDiv ul{
  margin: 10px 0px;
}
.paginationDiv button {
  border: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: transparent !important;
  color: #4b0079 !important;
  box-shadow: none !important;
}

.saveAndExit {
  display: flex !important;
  float: right;
  padding: 0px;
  align-items: flex-start;
}

.saveAndExit button {
  padding-left: 15px !important;
  padding-right: 15px !important;
  box-shadow: none !important;
}

.topPaginationButtonDiv {
  display: flex !important;
  float: right;
  margin-left: 0px;
  align-items: center;
}

.TabOptions button {
  padding: 0px;
  margin-top: 10px;
  margin-right: 4px;
  border: 0px;
  background-color: transparent !important;
}

.menuButton button p {
  border: 1px solid #4b0079;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  background-color: white;
  padding: .5em 1.5em;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px;
}

.menuButton {
  display: inline-block;
  margin-left: 40px;
  margin-top: 40px;
  margin-right: 4px;
  border: 0px;
  background-color: transparent !important;
}

.TabOptionsInactive p {
  background-color: #fcfdfd !important;
  color: #707070 !important;
}

.TabOptionsActive p {
  color: #0051a8 !important;
}

.para {
  z-index: 1;
  margin: 0px;
  font-size: 12px !important;
  color: #4b0079 !important;
}

.crossBtn {
  margin-right: 10px !important;
  margin-bottom: 20px !important;
  min-width: 0px !important;
  float: right !important;
  position: relative;
}

.crossBtn button {
  min-width: 0px !important;
}

.crossBtn .cancleTooltip {
  visibility: hidden;
  width: 60px;
  color: #4b0079 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  left: -10px;
  top: -20px;
}

.crossBtn:hover .cancleTooltip {
  visibility: visible;
}

.referenceWrap {
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topstepperButonsWrap {
  display: flex;
}
.allDocumentDialogboxPaper {
  max-width: 90%; 
  min-width: 70%; 
  width: auto; 
}
.allDocumentDialogboxPaper table {
  width: 100%;
  table-layout: fixed;
}

.allDocumentDialogboxPaper table th,
.allDocumentDialogboxPaper table td {
  width: 33.33%; 
  text-align: left; 
}
.allDocumentDialogboxPaper table th:last-child{
  width: 40%;
}
.fileNameContainer {
  vertical-align: middle;
}
.fileNameText {
  width: calc(100% - 80px);
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.fileNameDownload {
      width: 40px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
}
.fileNameDelete {
  width: 40px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
}
.uploadFilePaper{
  margin-left: 280px;
  margin-bottom: 12px;
  margin-top: 12px;
}
.tableStyle th{
  background-color: #ded0e9 !important;
}
.DialogBoxContext .noteTitle{
  color: #4b0079 !important;
  font-weight: bold;
  font-size: 0.9rem;
  margin: 199px 0px 0px 3px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  .top_nav {
    flex-direction: column;
    margin-bottom: 15px;
  }
  .saveAndExit {
    justify-content: end;
  }
  .nextBtn{
    margin-left: 10px;
    margin-right: 0px !important;
  }
}