body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #707070;
  font-size: 14px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.reviewPageDialogBox {
  width: 337px;
}

.toolbarClassName {
  border-bottom: 1px solid #4b0079 !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  margin-bottom: 0px !important;
  background-color: #4b0079 !important;
  font-size: 14px !important;
}

.editorClassName {
  padding: 5px 10px !important;
  height: 242px !important;
}

.editorClassName>div {
  margin-top: 5px;
  height: 100%;
}

.editorClassName div {
  margin: 0px
}

#webpack-dev-server-client-overlay{
  display: none;
}