.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
}

.ErrorFormHelperText {
  color: #e53935 !important;
}

.FormHelperText {
  color: #4b0079 !important;
  padding: 0px 0px 10px 0px !important;
}

form.reviewPageDialogBox {
  width: 337px;
}

.CompanyFormHead {
  padding-top: 15px;
  padding-bottom: 10px;
}

.DetailsFormHead {
  padding-top: 0px;
  padding-bottom: 10px;
}

.AssessmentFormHead {
  padding-top: 15px;
  padding-bottom: 0px;
}

.CompanyFormHead,
.DetailsFormHead,
.AssessmentFormHead {
  font-weight: 500;
  font-size: 16px;
  display: block;
  padding-left: 0px;
}

.PiiDataSwitch,
.PiiDataSwitchCheck {
  width: 136px !important;
  padding: 4px !important;
  border: 1px solid #4b0079;
  border-radius: 20px;
  z-index: 2 !important;
  height: 39px !important;
}

.PiiDataSwitch>span,
.PiiDataSwitchCheck>span {
  padding: 4px;
}

.PiiDataSwitch>span {
  transform: translateX(40px) !important;
}

.PiiDataSwitch>span+span,
.PiiDataSwitchCheck>span+span {
  border-radius: 20px;
  background-color: transparent !important;
}

.PiiDataSwitch>span>span>span,
.PiiDataSwitchCheck>span>span>span {
  width: 70px;
  background-color: #4b0079;
  height: 28px;
  border-radius: 25px;
}

.PiiDataSwitchLabels {
  position: absolute;
  width: 116px;
  left: 0;
  top: 0;
}

.pgswitch {
  position: relative;
  margin: 0px 350px;
}

.PiiDataSwitchLabels>span {
  width: 58px;
  justify-content: center;
  font-weight: 500;
  display: inline-flex;
  padding: 12px 0px;
}

.PiiDataSwitchCheck+span .PiiDataSwitchNo,
.PiiDataSwitch+span .PiiDataSwitchYes {
  color: #fff;
}

.PiiDataSwitchCheck+span .PiiDataSwitchYes,
.PiiDataSwitch+span .PiiDataSwitchNo {
  color: #4b0079;
}

.PiiDataSwitchCheck>span>span>span::before {
  content: "Standard";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 6px 0px;
  justify-content: center;
}

.PiiDataSwitch>span>span>span::before {
  content: "Custom";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 5px 0px;
  justify-content: center;
}

.CompanySelect {
  color: unset !important;
}

.OtherOfficeWrap>div>div>svg {
  width: 2em;
  font-size: 1.2rem;
  height: 1.5em;
}

.OtherOfficeWrap>div>div+div+div>button svg {
  width: 2em;
  font-size: 1.2rem;
  height: 1.5em;
}

.OtherOfficeWrap>div {
  border-radius: 10px;
}

.OtherOfficeWrap>div>div>div {
  padding: 0px;
  align-items: center;
}

.OtherOfficeWrap>div>div+div {
  line-height: 1.5 !important;
  font-size: 16px;
  font-weight: 500 !important;
  padding: 0px;
  align-items: center;
  display: inline-flex;
}

.DialogBoxTitle {
  text-align: center;
  margin: 0px;
  padding: 16px !important;
  border-bottom: 1px solid #4b0079 !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02);
}

.DialogBoxTitle h1 {
  margin: 0px !important;
}

.DialogBoxContext p {
  color: #707070;
}

.DialogBoxContext {
  padding: 16px !important;
}
