.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
}

.FilterAddWrap {
  float: right;
  margin-top: 20px;
  text-align: right;
}


.TabOptionsInactive p {
  background-color: #fcfdfd !important;
  color: #707070 !important;
}

.para {
  color: white;
  z-index: 1;
  margin: 1px;
}

.topstepperButons {
  margin-left: 10px !important;
  display: inline-block !important;
}

.topstepperButons button {
  border: 0px !important;
  padding: 27px 16px;
}

.FilterInput {
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.prevBtn {
  display: inline-flex !important;
  margin: 0px 6px 15px 0px;
  align-items: flex-start;
}

.prevBtn button {
  width: 130px;
}

.cancelButton {
  margin-left: 10px !important;
  display: inline-block !important;
}

.salText {
  font-weight: 600;
  margin-top: 5%;
}

.sal_levels {
  background-color: rgb(177, 182, 253);
  font-weight: 600;
  border-radius: 5%;
}

.selected_level {
  background-color: white;
  border-radius: 5%;
}

.TabOptionsActive>span {
  color: #4b0079 !important;
}

.TabOptionsInactive {
  background-color: #fcfdfd !important;
}

.TabOptions button {
  border: 1px solid #4b0079 !important;
  border-radius: 10px !important;
  margin-right: 4px !important;
  color: #707070 !important;
  font-weight: 600 !important;
  background-color: white !important;
  padding: 10px !important;
  margin-top: 12px;
  margin-bottom: 12px;
}

.CompanyFormHead {
  font-weight: 500;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 5px;
  display: block;
  margin-bottom: 5px;
}

.CompanySelect {
  color: unset !important;
}

.TabOptionsActive>span {
  background-color: #4b0079 !important;
}

.TabOptionsInactive {
  background-color: #fcfdfd !important;
  margin: 1px;
}

.TabOptions button {
  border: 1px solid #4b0079 !important;
  border-radius: 10px !important;
  margin-right: 4px !important;
  color: #707070 !important;
  font-weight: 600 !important;
  background-color: white !important;
  padding: 10px !important;
  margin-top: 12px;
  margin-bottom: 12px;
}

.nextBtn {
  margin-left: 10px;
  display: inline-block;
}

.Stdselect h4 {
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 5px;
  margin-top: 20px;
}

.stdmsg span {
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 3px;
  vertical-align: top;
}

.Companyrequirenos {
  font-size: 16px;
  font-weight: 600;
}

.Companyrequireblock {
  padding: 15px;
  background-color: #e1e1e1;
  border-radius: 10px;
  margin: 15px 0px;
}

.stdinner {
  padding: 0px 0px 0px 10px;
}

.cancelButton button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: transparent !important;
  color: #4b0079 !important;
  box-shadow: none !important;
  border: 0px !important;
}

.crossBtn {
  margin-right: 10px !important;
  margin-bottom: 20px !important;
  min-width: 0px !important;
  float: right !important;
  position: relative;
}

.crossBtn button {
  min-width: 0px !important;
}

.crossBtn .cancleTooltip {
  visibility: hidden;
  width: 60px;
  color: #4b0079 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  left: -10px;
  top: -20px;
}

.crossBtn:hover .cancleTooltip {
  visibility: visible;
}

.nextBtn {
  margin-right: 10px;
  display: inline-flex;
  align-items: flex-start;
}

.nextBtn button {
  width: 85px;
}

.topstepperWrap {
  display: flex;
}

.topstepperButons {
  margin-left: 0px !important;
  display: flex !important;
  flex-direction: row;
}

.topstepperButons button {
  border: 0px !important;
  padding: 27px 16px;
}

.FooterGridWrap {
  display: flex;
  justify-content: space-between;
}
.CompanyrequireblockWrap {
  display: flex;
  margin-bottom: 4px;
  align-items: center;
}

.Companyrequire {
  width: 200px;
  display: inline-flex;
  align-items: center;
}
.topstepperWrap button {
  border: 0px !important;
  padding: 3px 0px;
  margin: 0;
}

.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}

.DialogBoxTitle {
  text-align: center;
  margin: 0px;
  padding: 16px !important;
  border-bottom: 1px solid #4b0079 !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02);
}

.DialogBoxTitle h1 {
  margin: 0px !important;
}

.CreditMainOfficeDialogboxPaper .DialogBoxTitle {
  display: none;
}
.DialogBoxContext {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px !important;
}
.DialogBoxContext p {
  color: #707070;
}
