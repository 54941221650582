.paper {
    margin-bottom: 20px;
    padding: 28px 25px 17px 25px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
      0 0 2px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 20px !important;
    border: 1px solid #4b0079;
  }
  
  .ContinueButton {
    background-color: white !important;
    border: solid 2px #303f9f !important;
    border-radius: 12px !important;
    margin-top: 100px;
    color: #303f9f !important;
  }
  
  .Margin {
    margin: 4px;
  }
  
  .gridSection h4 {
    text-align: center;
  }
  .tableStyle {
    width: 100%;
    margin-bottom: 25px;
  }
  .tableStyle th {
    width: 40% !important;
  }
  
  .tableStyle th,
  .tableStyle td {
    border-radius: 0px !important;
    border: 1px solid #ccc;
  }
  
  .rank_table table tr th:nth-child(1) {
    text-align: center !important;
  }
  
  .rank_table th {
    border-radius: 0px !important;
  }
  
  .rank_table table tr td:nth-child(1) {
    width: 5% !important;
    text-align: center !important;
  }
  
  .rank_table table tfoot tr td:nth-child(1) {
    width: 100% !important;
  }
  
  tfoot tr td {
    width: 100% !important;
    text-align: left !important;
    display: block !important;
  }
  
  .rank_table table tr td:nth-child(2) {
    width: 45% !important;
  }
  
  .rank_table table tr td:nth-child(3) {
    width: 5% !important;
  }
  
  .rank_table table tr td:nth-child(4) {
    width: 20% !important;
  }
  
  .rank_table table tr td:nth-child(5) {
    width: 25% !important;
  }
  
  .rank_table table tr th:nth-child(5) {
    text-align: left !important;
  }
  
  .cal_head {
    margin-bottom: 20px !important;
    border: none !important;
    background-color: #d3d7dc;
    font-weight: 600;
    border-radius: 5%;
    padding: 10px 15px !important;
    display: inline-block;
    margin-top: 15px !important;
    margin-right: 15px !important;
    font-size: 12px;
  }
  
  .cal_level {
    background-color: white;
    border-radius: 5%;
    margin-top: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: normal;
  }
  
  .caclulated_table table {
    width: 100% !important;
    border-collapse: collapse;
  }
  
  .caclulated_table th:nth-child(4) {
    text-align: left;
  }
  
  .caclulated_table th {
    border-radius: 0px !important;
  }
  
  .caclulated_table td:nth-child(2) {
    width: 25% !important;
  }
  
  .caclulated_table td:nth-child(3) {
    width: 25% !important;
  }
  
  .caclulated_table td:nth-child(4) {
    width: 25% !important;
  }
  
  .borderButton {
    display: inline-block;
  }
  
  .borderButton button {
    background-color: transparent !important;
    color: #4b0079 !important;
  }
  
  .hedaerCell {
    color: #4b0079 !important;
    font-weight: 500 !important;
    width: 50% !important;
  }
  
  .caclulated_table th:first-child,
  .rank_table th:first-child {
    border-top-left-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
  }
  
  .caclulated_table th:last-child,
  .rank_table th:last-child {
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }
  
  /*Switch*/
  
  .PiiDataSwitch,
  .PiiDataSwitchCheck {
    width: 100px !important;
    padding: 4px !important;
    border: 1px solid #4b0079;
    border-radius: 20px;
    z-index: 2 !important;
    height: 39px !important;
  }
  
  .PiiDataSwitch>span,
  .PiiDataSwitchCheck>span {
    padding: 4px;
  }
  
  .PiiDataSwitch>span {
    transform: translateX(40px) !important;
  }
  
  .PiiDataSwitch>span+span,
  .PiiDataSwitchCheck>span+span {
    border-radius: 20px;
    background-color: transparent !important;
  }
  
  .PiiDataSwitch>span>span>span,
  .PiiDataSwitchCheck>span>span>span {
    width: 48px;
    background-color: #4b0079;
    height: 28px;
    border-radius: 25px;
  }
  
  .PiiDataSwitchLabels {
    position: absolute;
    width: 116px;
    left: 0;
    top: 0;
  }
  
  .pgswitch {
    position: relative;
    margin: 7px 0px;
  }
  
  .PiiDataSwitchLabels>span {
    width: 48px;
    justify-content: center;
    font-weight: 500;
    display: inline-flex;
    padding: 12px 0px;
  }
  
  .PiiDataSwitchCheck+span .PiiDataSwitchNo,
  .PiiDataSwitch+span .PiiDataSwitchYes {
    color: #fff;
  }
  
  .PiiDataSwitchCheck+span .PiiDataSwitchYes,
  .PiiDataSwitch+span .PiiDataSwitchNo {
    color: #4b0079;
  }
  
  .PiiDataSwitchCheck>span>span>span::before {
    content: "No";
    color: #fff !important;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    padding: 6px 0px;
    justify-content: center;
  }
  
  .PiiDataSwitch>span>span>span::before {
    content: "YES";
    color: #fff !important;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    padding: 5px 0px;
    justify-content: center;
  }
  
  .FooterActions button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .prevBtn {
    display: inline-block !important;
  }
  
  .cancelButton {
    display: inline-block !important;
  }
  
  .FormHelperText {
    color: #e53935 !important;
    padding: 0px 0px 10px 0px !important;
    display: block !important;
  }
  
  .errormsg {
    display: inline-block !important;
    width: 100% !important;
  }
  
  .DialogBoxTitle {
    text-align: center;
    margin: 0px;
    padding: 16px !important;
    border-bottom: 1px solid #0051a8 !important;
    box-shadow: 0px 3px rgba(0, 0, 0, 0.02);
  }
  
  .DialogBoxTitle h1 {
    margin: 0px !important;
  }
  
  .DialogBoxContext p {
    color: #707070;
  }
  
  .DialogBoxContext {
    padding: 16px !important;
  }
  
  .OtherOfficeWrap legend {
    font-size: 14px;
  }
  
  .MainOfficeDialogboxPaper {
    height: 215px !important;
    border: 1px solid #0051a8 !important;
    border-radius: 20px !important;
    box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  }
  
  .MainOfficeDialogboxContainer {
    max-width: 80%;
    min-width: 50% !important;
    margin: 0 auto;
  }
  
  .top-heading {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  h4 {
    text-align: center;
  }
  
  .elementListStyle {
    padding: 0px !important;
    margin: 0px !important;
    font-family: "Calibri" !important;
  }
  
  .elementListStyle ul li {
    padding: 0px !important;
    font-family: "Calibri" !important;
  }
  
  .ruler {
    border-top: 1px solid #cacaca;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  p {
    color: #707070;
    font-size: 12px;
  }
  
  .bulletStyle {
    color: #4b0079 !important;
    display: inline-flex;
    min-width: 56px;
    flex-shrink: 0;
  }
  
  h3 {
    color: #4B0079 !important;
  }
  
  .prevBtn button {
    padding: 3px 14px !important;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%) !important;
    line-height: 1.846 !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    border: 1px solid #4b0079 !important;
  }

  .paper h3 {
    margin-bottom: 5px;
  }
  
  .paper div:last-child table{
    margin-bottom: 0px !important;
  }