.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
}

.ContinueButton {
  background-color: #4b0079 !important;
  border: 1px solid #4b0079;
  border-radius: 20px !important;
  margin-top: 100px;
  color: #fff !important;
  padding: 3px 14px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
  border-radius: 20px;
  line-height: 1.846;
  display: inline-block;
  margin-left: 10px;
  text-decoration: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  outline: 0 !important;
  background-image: none !important;
}

.Margin {
  margin: 4px;
}

.docstyle {
  visibility: hidden !important;
  height: 1px !important;
  overflow: hidden !important;
}



/*Switch*/

.PiiDataSwitch,
.PiiDataSwitchCheck {
  width: 100px !important;
  padding: 4px !important;
  border: 1px solid #4b0079;
  border-radius: 20px;
  z-index: 2 !important;
  height: 39px !important;
}

.PiiDataSwitch>span,
.PiiDataSwitchCheck>span {
  padding: 4px;
}

.PiiDataSwitch>span {
  transform: translateX(40px) !important;
}

.PiiDataSwitch>span+span,
.PiiDataSwitchCheck>span+span {
  border-radius: 20px;
  background-color: transparent !important;
}

.PiiDataSwitch>span>span>span,
.PiiDataSwitchCheck>span>span>span {
  width: 48px;
  background-color: #4b0079;
  height: 28px;
  border-radius: 25px;
}

.PiiDataSwitchLabels {
  position: absolute;
  width: 116px;
  left: 0;
  top: 0;
}

.pgswitch {
  position: relative;
  margin: 7px 0px;
}

.PiiDataSwitchLabels>span {
  width: 48px;
  justify-content: center;
  font-weight: 500;
  display: inline-flex;
  padding: 12px 0px;
}

.PiiDataSwitchCheck+span .PiiDataSwitchNo,
.PiiDataSwitch+span .PiiDataSwitchYes {
  color: #fff;
}

.PiiDataSwitchCheck+span .PiiDataSwitchYes,
.PiiDataSwitch+span .PiiDataSwitchNo {
  color: #4b0079;
}

.PiiDataSwitchCheck>span>span>span::before {
  content: "No";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 6px 0px;
  justify-content: center;
}

.PiiDataSwitch>span>span>span::before {
  content: "YES";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 5px 0px;
  justify-content: center;
}

.topstepperButons {
  margin-left: 10px !important;
  display: inline-block !important;
}

.topstepperButons button {
  border: 0px !important;
  padding: 3px 0px;
  margin: 0;
}

.cancelButton {
  display: inline-block !important;
}

.FormHelperText {
  color: #e53935 !important;
  padding: 0px 0px 10px 0px !important;
  display: block !important;
}

.errormsg {
  display: inline-block !important;
  width: 100% !important;
}

.DialogBoxTitle {
  text-align: center;
  margin: 0px;
  padding: 16px !important;
  border-bottom: 1px solid #4b0079 !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02);
}

.DialogBoxTitle h1 {
  margin: 0px !important;
}

.DialogBoxContext p {
  color: #707070;
}

.DialogBoxContext {
  padding: 16px !important;
}

.OtherOfficeWrap legend {
  font-size: 14px;
}

.MainOfficeDialogboxPaper {
  height: 215px !important;
  border: 1px solid #4b0079 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
}

.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}

.cset_band {
  border: 1px solid #4b0079 !important;
  background-color: #ffffff !important;
  padding: 5px;
  border-radius: 20px;
}

.cset_band>div {
  min-height: unset;
}

.tabBoxPanel {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.cset_band button {
  padding: 5px 10px;
  border-radius: 20px;
  min-height: unset;
}

.cset_band button span {
  font-weight: 500 !important;
}

.selectedTab {
  color: #ffffff !important;
  background-color: #4b0079 !important;
}

.PrivateTabIndicator {
  /* background-color : white !important */
  display: none;
}

.prevBtn,
.nextBtn {
  display: inline-flex !important;
  align-items: flex-start;
}

.prevBtn button {
  width: 130px
}

.nextBtn button {
  width: 85px;
  margin-right: 20px;
}

.cancelButton {
  display: inline-block !important;
}

.cancelButton button {
  border: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: transparent !important;
  color: #4b0079 !important;
  box-shadow: none !important;
}

.crossBtn {
  margin-right: 10px !important;
  margin-bottom: 20px !important;
  min-width: 0px !important;
  float: right !important;
  position: relative;
  margin-left: auto; 
}

.crossBtn button {
  min-width: 0px !important;
}

.crossBtn .cancleTooltip {
  visibility: hidden;
  width: 60px;
  color: #4b0079 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  left: -10px;
  top: -20px;
}

.crossBtn:hover .cancleTooltip {
  visibility: visible;
}

.FooterActions {
  display: flex;
}

.topstepperButonsWrap {
  display: inline-flex;
}