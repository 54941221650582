.paper {
    padding: 35px;
    background-color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
    border-radius: 20px !important;
    border: 1px solid #4b0079;
    position: relative;
}

.BackToButton {
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: normal !important;
    color: #4b0079 !important;
    outline: 0 !important;
    text-transform: unset !important;
    padding-right: 10px !important;
    margin-bottom: 3px;
  }
  
.BackToButton img {
    width: 14px !important;
    margin-right: 3px;
}

.FilterAddWrap {
    text-align: right;
}

.ButtonGroup2 {
    display: inline-block !important;
    margin-bottom: 20px;
    margin-right: 10px;
    height: 15px;
}

.content{
    padding: 8px !important;
    line-height: 1.846 !important;
    color: #707070 !important;
    font-size: 16px !important;
    font-weight: normal !important;
    vertical-align: middle;
}

.header{
    padding: 8px 0px !important;
    border-bottom: none !important;
    line-height: 1.5 !important;
    font-size: 24px !important;
    color: #4b0079 !important;
    font-weight: 700 !important;
    background-color: #fcfaff !important;
    text-align: center;
    vertical-align: middle !important;
}

.subHeader{
    /* margin:10px; */
    padding: 6px ;
    border-bottom: none ;
    line-height: 1.2 ;
    font-size: 18px ;
    color: #4b0079 ;
    font-weight: 700 ;
    vertical-align: middle ;
}