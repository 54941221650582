.filterContainer {
  padding: 0px 0px 5px 0px;
  width: auto;
}

.ButtonGroup2 {
  display: inline-block !important;
  margin-bottom: 10px;
}

.FilterAddWrap {
  float: right;
  margin-top: 20px;
  text-align: right;
}

.tableBox {
  border: #4b0079 1px solid !important;
  margin: 0px 5px 5px 5px;
  border-radius: 10px;
  padding: 10px;
}

.FilterWrap2 {
  margin: 6px 5px 0px 5px;
  padding: 1px;
}

.FilterWrap3 {
  margin: 6px 5px 0px 5px;
  padding: 1px;
}

.helpMenus {
  border-radius: 50% !important;
  padding: 3px 14px !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%) !important;
  line-height: 1.846 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  border: 1px solid #4b0079 !important;
  display: inline-block !important;
  margin-left: 10px !important;
  width: 150px !important;
  text-decoration: none !important;
  outline: 0 !important;
  background-color: #4b0079 !important;
  background-image: none !important;
  color: white !important;
}

.FilterWrap3 button {
  padding-left: 15px !important;
  padding-right: 15px !important;
  background-color: #4b0079 !important;
  color: white !important;
}

.FilterWrap2 button {
  padding-left: 15px !important;
  padding-right: 15px !important;
  background-color: transparent !important;
  color: #4b0079 !important;
}

.FilterWrap1 {
  margin: 6px 5px 0px 15px;
  padding: 1px;
}

.BackToButton {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #4b0079 !important;
  outline: 0 !important;
  text-transform: unset !important;
  padding-right: 10px !important;
  margin-bottom: 3px;
}
