.FooterActions button {
    margin-right: 10px;
    margin-bottom: 10px;
}
.borderButton {
    display: inline-block;
}
.borderButton button {
    background-color: transparent !important;
    color: #4b0079 !important;
    cursor: pointer;
}
.paper {
    margin-bottom: 20px;
    padding: 28px 25px 17px 25px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
      0 0 2px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 20px !important;
    border: 1px solid #4b0079;
}
.FooterActions button {
    margin-right: 10px;
    margin-bottom: 10px;
}
.tableStyle {
  width: 100%;
  margin-top: 15px;
}
.tableStyle th {
  width: 40% !important;
}
.tableStyle {
  margin-bottom: 25px;
} 
.tableStyle th,
.tableStyle td {
  border-radius: 0px !important;
  border: 1px solid #ccc;
}
.qtext, .qtext *, .qtext pre {
  font-size: 12px;
  line-height: 18px;
  color: #707070;
}
.qtext{
  padding: 0px 10px;
}
.qtext pre {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}