
.tableStyle td:nth-child(1) {
    width: 30% !important;
  }
  
  .tableStyle td:nth-child(3) {
    width: 20% !important;
  }
  .tableStyle th {
    width: 40% !important;
  }
  
  .tableStyle th,
  .tableStyle td {
    border: 1px solid #ccc;
    border-radius: 0px !important;
  }
  
  .tableStyle td {
    width: 100%;
  }

  .securityPlanTable .tableStyle td:nth-child(3) {
    width: 30% !important;
  }
  .securityPlanTable .tableStyle td:nth-child(2) {
    width: 50% !important;
  }
  .securityPlanTable .tableStyle {
    width: 100%;
  }
  .securityPlanTable .tableStyle td *{
    font-size: 12px;
    color: #707070;
  }

  .QuestionViewMIL {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 30px 0px 15px 0px;
  }