.DialogboxPaper {
  border-radius: 24px !important;
  margin-top: 66px !important;
  border-bottom: 1px solid #0051A8 !important;
  width: auto !important;
  max-width: 800px !important;
  height: auto !important
}


.Dialogbox {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
  max-height: 550px;
}

.DialogActions {
  padding: 16px !important;
  border-top: 1px solid #4b0079;
}

.DialogTitle, .DialogBody {
  padding: 0px !important;
}

.ReactRootDialog {
  background-color: rgba(255, 255, 255, 0.85);
}

.DialogBody form {
  padding: 16px !important;
  margin: 10px auto 0 auto;
  min-width: 300px;
}