.GridBox {
    background-color: #fff !important;
    border: 1px solid #4b0079 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12) !important;
    border-radius: 20px !important;
    padding: 15px !important;
    margin-bottom: 35px !important;
}

.dash_block {
    display: inline-block !important;
    background-color: #fff;
    border: 1px solid #4b0079;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 2px 0 rgba(0, 0, 0, .12);
    border-radius: 20px;
    justify-content: center;
    vertical-align: top;
    text-align: center;
    width: 100%;
}
.dash_head {
	  font-size: 20px !important;
	  margin-top: 40px !important;
    margin-bottom: 15px !important;
    color: #4b0079  !important;
}

.dash_count {
	  font-size: 30px !important;
	  margin-top: 20px !important;
    margin-bottom: 30px !important;
    cursor: pointer;
    font-weight: bold;
}