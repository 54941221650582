.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
}

.ContinueButton {
  background-color: white !important;
  border: solid 2px #303f9f !important;
  border-radius: 12px !important;
  margin-top: 100px;
  color: #303f9f !important;
}

.PiiDataSwitch,
.PiiDataSwitchCheck {
  width: 100px !important;
  padding: 4px !important;
  border: 1px solid #4b0079;
  border-radius: 20px;
  z-index: 2 !important;
  height: 39px !important;
}

.PiiDataSwitch>span,
.PiiDataSwitchCheck>span {
  padding: 4px;
}

.PiiDataSwitch>span {
  transform: translateX(40px) !important;
}

.PiiDataSwitch>span+span,
.PiiDataSwitchCheck>span+span {
  border-radius: 20px;
  background-color: transparent !important;
}

.PiiDataSwitch>span>span>span,
.PiiDataSwitchCheck>span>span>span {
  width: 48px;
  background-color: #4b0079;
  height: 28px;
  border-radius: 25px;
}

.PiiDataSwitchLabels {
  position: absolute;
  width: 116px;
  left: 0;
  top: 0;
}


.prevBtn {
  display: inline-block !important;
}

.gridSection h4 {
  text-align: center;
}

.tableStyle td:nth-child(2) {
  width: 100% !important;
}

.tableStyle th {
  width: 40% !important;
}

.tableStyle th,
.tableStyle td {
  border: 1px solid #ccc;
}

.tableStyle th,
.tableStyle td {
  border-radius: 0px !important;
}

.tableStyle td {
  width: 100%;
}

.rank_table table tr th:nth-child(1) {
  text-align: center !important;
}

.rank_table th {
  border-radius: 0px !important;
}

.rank_table table tr td:nth-child(1) {
  width: 5% !important;
  text-align: center !important;
}

.rank_table table tfoot tr td:nth-child(1) {
  width: 100% !important;
}

tfoot tr td {
  width: 100% !important;
  text-align: left !important;
  display: block !important;
}

.rank_table table tr td:nth-child(2) {
  width: 45% !important;
}

.rank_table table tr td:nth-child(3) {
  width: 5% !important;
}

.rank_table table tr td:nth-child(4) {
  width: 20% !important;
}

.rank_table table tr td:nth-child(5) {
  width: 25% !important;
}

.rank_table table tr th:nth-child(5) {
  text-align: left !important;
}


.cal_head {
  background-color: #d3d7dc;
  margin-bottom: 20px !important;
  border: none !important;
  font-weight: 600;
  border-radius: 5%;
  padding: 10px 15px !important;
  display: inline-block;
  margin-top: 15px !important;
  margin-right: 15px !important;
  font-size: 12px;
}

.cal_level {
  background-color: white;
  border-radius: 5%;
  margin-top: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
}

.caclulated_table table {
  width: 100% !important;
  border-collapse: collapse;
}

.caclulated_table th:nth-child(4) {
  text-align: left;
}

.caclulated_table th {
  border-radius: 0px !important;
}

.caclulated_table td:nth-child(2) {
  width: 25% !important;
}

.caclulated_table td:nth-child(3) {
  width: 25% !important;
}

.caclulated_table td:nth-child(4) {
  width: 25% !important;
}

.borderButton {
  display: inline-block;
}

.borderButton button {
  background-color: transparent !important;
  color: #4b0079 !important;
}

.hedaerCell {
  color: #4b0079 !important;
  font-weight: 500 !important;
  width: 50% !important;
}

.caclulated_table th:first-child,
.rank_table th:first-child {
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.caclulated_table th:last-child,
.rank_table th:last-child {
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}


.Margin {
  margin: 4px;
}


.PiiDataSwitchLabels {
  position: absolute;
  width: 116px;
  left: 0;
  top: 0;
}

.pgswitch {
  position: relative;
  margin: 7px 0px;
}

.PiiDataSwitchLabels>span {
  width: 48px;
  justify-content: center;
  font-weight: 500;
  display: inline-flex;
  padding: 12px 0px;
}

.PiiDataSwitchCheck+span .PiiDataSwitchNo,
.PiiDataSwitch+span .PiiDataSwitchYes {
  color: #fff;
}

.PiiDataSwitchCheck+span .PiiDataSwitchYes,
.PiiDataSwitch+span .PiiDataSwitchNo {
  color: #4b0079;
}

.PiiDataSwitchCheck>span>span>span::before {
  content: "No";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 6px 0px;
  justify-content: center;
}

.PiiDataSwitch>span>span>span::before {
  content: "YES";
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 5px 0px;
  justify-content: center;
}

.FooterActions button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.cancelButton {
  display: inline-block !important;
}

.FormHelperText {
  color: #e53935 !important;
  padding: 0px 0px 10px 0px !important;
  display: block !important;
}

.errormsg {
  display: inline-block !important;
  width: 100% !important;
}

.DialogBoxTitle {
  text-align: center;
  margin: 0px;
  padding: 16px !important;
  border-bottom: 1px solid #0051a8 !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02);
}

.DialogBoxTitle h1 {
  margin: 0px !important;
}

.DialogBoxContext p {
  color: #707070;
}

.DialogBoxContext {
  padding: 16px !important;
}

.OtherOfficeWrap legend {
  font-size: 14px;
}

.MainOfficeDialogboxPaper {
  height: 215px !important;
  border: 1px solid #0051a8 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
}

.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}

.top-heading {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

h4 {
  text-align: center;
}

.elementListStyle {
  padding: 0px !important;
  margin: 0px !important;
  font-family: "Calibri" !important;
}

.elementListStyle ul li {
  padding: 0px !important;
  font-family: "Calibri" !important;
}

.ruler {
  border-top: 1px solid #cacaca;
  margin-top: 20px;
  margin-bottom: 20px;
}

p {
  color: #707070;
  font-size: 12px;
  padding: 0;
}

.bulletStyle {
  color: #4b0079 !important;
  display: inline-flex;
  min-width: 56px;
  flex-shrink: 0;
}

h3 {
  color: #4B0079 !important;
}

.securityPlanTable .tableStyle td:nth-child(3) {
  width: 30% !important;
}

.securityPlanTable .tableStyle td:nth-child(2) {
  width: 50% !important;
}

.securityPlanTable .tableStyle {
  width: 100%;
}

.securityPlanTable .tableStyle td * {
  font-size: 12px;
  color: #707070;
}

/* //////////////////////// */
.goalrow {
  padding: 0 0 0 0.25rem;
  height: auto;
  display: flex;
  margin-left: -15px;
}

.col1 .goalrow:last-child {
  border-bottom: 0px;
}

.goalrowtext {
  margin: 0;
  font-size: 8px;
}

.tinytext {
  font-size: 8px;
}

.divided-box {
  border-top: 1px solid black;
}



.appendixLabel {
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0;
}

.parent {
  width: 792px;
}

.parent * {
  font-family: Arial;
}


.crrResultsProgressBar {
  height: 16px;
  background: rgb(199, 121, 44);
  display: inline-block;
  position: relative;
  margin-left: 160px;
  top: calc(-1 * calc(calc(50px / 2) + calc(16px / 2)));
  box-sizing: border-box;
  border-left: solid 1px black;
}

.depictionTitle {
  font-family: Arial;
  font-weight: bold;
  font-size: 18pt;
  margin: 0;
}

.crrResultsTable {
  width: 792px;
  font-size: 0;
}

.parent {
  width: 792px;
}

.parent * {
  font-family: Arial;
}

.crrResultsTableRow {
  width: 792px;
  height: 50px;
  box-sizing: border-box;
  border-top: solid 1px black;
  border-right: solid 1px black;
  font-size: 0;
  /*Done to prevent funky margins*/
}

.crrResultsDomainCell {
  height: 50px;
  font-size: 12px;
  vertical-align: top;
  font-weight: 600;
  border-right: solid 1px black;
  display: inline-block;
}

.crrResultsTableDomainName {
  width: 160px;
}

.crrResultsProgressBar {
  height: 16px;
  background: rgb(199, 121, 44);
  display: inline-block;
  position: relative;
  margin-left: 160px;
  top: calc(-1 * calc(calc(50px / 2) + calc(16px / 2)));
  box-sizing: border-box;
  border-left: solid 1px black;
}

.crrResultsLabelCell {
  width: 160px;
  height: 50px;
  font-size: 14px;
  vertical-align: top;
  font-weight: 200;
  box-sizing: border-box;
  border-right: solid 1px black;
  display: inline-block;
  text-align: right;
}



.mil1grid {
  padding-right: 2px;
  display: inline-block;
  height: 50px;
  width: 79px;
  box-sizing: border-box;
}

.mil1override {
  box-sizing: border-box;
  border-right: solid 1px gray !important;
}

.miluppergrid {
  display: inline-block;
  height: 50px;
  width: calc(var(--mil-upper-width) / 4);
  box-sizing: border-box;
}

.rbl {
  box-sizing: border-box;
  border-right: solid 1px lightgray;
}

.rbd {
  box-sizing: border-box;
  border-right: solid 1px gray;
}

.milnumberlabels {
  font-size: 12px;
  padding-right: 5px;
}

.crrTableLabel {
  text-align: right;
  vertical-align: top;
}

.uppermillabeldesc,
.uppermillabelbold {
  font-size: 8px;
  text-align: right;
  padding-left: 10px;
  padding-right: 5px;
}

.uppermillabelbold {
  font-size: 8px;
  font-weight: 800;
  white-space: nowrap;
  padding-left: 5px;
}

.ccrLastLabel {
  width: var(--mil-upper-width-individual);
  margin-left: -9px;
  font-size: 18px;
  /* Minus 9, one for each of the borders included in the row*/
}

.chartTitle {
  font-weight: 600;
  font-size: 1.3em;
  text-align: center;
}

.break {
  page-break-before: always;
}

.appendixlabel {
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0;
}

.function {
  font-weight: bolder;
  height: 100%;
  width: 75px;
  display: block;
  position: relative;
}

.container,
.containerFluid,
.containerLg,
.containerMd,
.containerSm,
.containerXl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  width: 100%;
}

.col1 {
  flex: 0 0 auto;
  max-width: unset;
}

.col6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col,
.col1,
.col10,
.col11,
.col12,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9,
.colauto,
.collg,
.collg1,
.collg10,
.collg11,
.collg12,
.collg2,
.collg3,
.collg4,
.collg5,
.collg6,
.collg7,
.collg8,
.collg9,
.collgauto,
.colmd,
.colmd1,
.colmd10,
.colmd11,
.colmd12,
.colmd2,
.colmd3,
.colmd4,
.colmd5,
.colmd6,
.colmd7,
.colmd8,
.colmd9,
.colmdauto,
.colsm,
.colsm1,
.colsm10,
.colsm11,
.colsm12,
.colsm2,
.colsm3,
.colsm4,
.colsm5,
.colsm6,
.colsm7,
.colsm8,
.colsm9,
.colsmauto,
.colxl,
.colxl1,
.colxl10,
.colxl11,
.colxl12,
.colxl2,
.colxl3,
.colxl4,
.colxl5,
.colxl6,
.colxl7,
.colxl8,
.colxl9,
.colxlauto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

h3.depictionTitle {
  font-family: Arial;
  font-weight: 700;
  font-size: 18pt;
  margin: 0;
}

svg.legendSvg text {
  font-size: 8px;
}

.appendixLabel {
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0;
}

.text-wrap {
  white-space: normal !important;
}

.header {
  padding: 0.5rem 0;
}

.domain {
  border-top: 1px solid black;
  padding: 0.25rem 0;
}

.pageMarginTop {
  page-break-before: always;
  min-width: 860px;
  border-top: 2px solid #769bb5;
}

.col3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.crrNistWrap .row:last-child {
  border-bottom: 0px;
}

.crrPerformance p,
.crrPerformance div,
.domain p,
.domain div {
  font-size: 10px;
}

.goalrowText {
  margin: 0;
  font-size: 8px;
}

.col5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.crrResultsTableLabelRow {
  width: 792px;
  height: 80px;
  box-sizing: border-box;
  border-top: white solid 0px;
  border-right: solid 1px black;
}

.crrResultsLabelCell {
  width: 160px;
  height: 50px;
  font-size: 14px;
  vertical-align: top;
  font-weight: 200;
  box-sizing: border-box;
  border-right: solid 1px black;
  display: inline-block;
  text-align: right;
}

.mil1Grid {
  padding-right: 2px;
  display: inline-block;
  height: 50px;
  width: 79px;
  box-sizing: border-box;
}

.mil1Override {
  box-sizing: border-box;
  border-right: solid 1px gray !important;
}

.milUpperGrid {
  display: inline-block;
  height: 50px;
  width: 79px;
  box-sizing: border-box;
}

.row.header div {
  padding: 0;
  font-size: 7.5px;
  padding: 0.1rem;
}

.crrNistCatSumWrap table th {
  background-color: black !important;
  color: #fff !important;
  font-size: 10px !important;
  font-weight: bold !important;
  text-align: left;
  height: 20px;
  border-radius: 0px;
  padding: 0px !important;
  border: 1px solid black !important;
}

.bb2 td {
  border-bottom: solid 2px black !important;
}

.crrNistCatSumWrap table tr,
.crrNistCatSumWrap table td {
  padding: 0 !important;
  text-align: left !important;
  page-break-inside: avoid !important;
}

.verticalText {
  color: #fff;
  transform: rotate(-90deg);
  padding: 50px 10px;
  line-height: 24px;
}

.function {
  background-color: rgb(69, 103, 183);
}
.crrNistCatSumWrap table td.summaryCellPadding {
  padding: 0px 1rem !important;
}
.justifyContentStart {
  justify-content: flex-start !important;
}

.flexWrap {
  flex-wrap: wrap !important;
}
.dFlex {
  display: flex !important;
}
.mb3, .my3 {
  margin-bottom: 1rem!important;
}
.alignItemsCenter {
  align-items: center!important;
}
.category {
  width: 300px;
}