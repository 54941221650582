.List {
  display: inline-block;
  width: 100%;
  padding: 0px !important;
  margin: 0px 0px 5px 0px !important;
}

.List Button {
  width: 100% !important;
  justify-content: left;
}

.ListItemIcon {
  margin-right: 20px;
}

.ListItemIcon img {
  width: 26px;
  height: 26px;
}

.ClosedItem .ListItemIcon {
  margin: 0px 7px 0px 0px !important;
}

.IconItem {
  border-radius: 12px !important;
  padding: 3px !important;
  margin: 0px !important;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px !important;
}

.SelectedItem.OpenItem,
.SelectedItem.OpenItem:hover,
.OpenItem:hover {
  border: 1px solid #0051a8 !important;
  background-color: #eff6fd !important;
}

.CompanySelect {
  color: unset !important;
}

.TabOptionsActive {
  background-color: #ded0e9 !important;
  border-radius: 12px !important;
  font-size: 12px !important;
  font-weight: bold;
}

.TabOptionsInactive {
  color: #000000 !important;
  margin: 0px !important;
  text-align: left !important;
  text-transform: capitalize;
  font-size: 13px !important;
  border-radius: 12px !important;
}

.TabOptionsActive p {
  margin: 0px;
  text-align: left !important;
  width: 100%;
  display: block;
  font-weight: bold;
}

.TabOptionsInactive p {
  color: #000000 !important;
  margin: 0px !important;
  text-align: left !important;
  font-weight: bold;
}

.para {
  z-index: 1;
  margin: 0px;
  width: 100%;
  height: 100%;
  font-size: 13px !important;
  color: #4b0079 !important;
  text-transform: capitalize;
}

.Completed {
  background-color: #6db665 !important;
  margin: 5px 0px 5px 0px !important;
}


.Completed .TabOptionsActive,
.InProgress .TabOptionsActive {
  background-color: transparent !important;
}

.InProgress {
  background-color: #e9f05d !important;
}

.InComplete {
  width: 100%;
  margin: 0px;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
}

.OpenItem {
  width: calc(100% - 30px) !important;
  padding-bottom: 0px !important;
  border: 1px solid transparent !important;
}

.OpenItem+div ul {
  margin-left: 15px;
}

.ClosedItem {
  box-shadow: inset 0px 11px 8px -10px #ccc !important;
  padding: 13px !important;
}

.ClosedItem.IconItem {
  border-radius: 0px !important;
  margin: 0px !important;
}

.SelectedItem.ClosedItem,
.SelectedItem.ClosedItem:hover,
.ClosedItem:hover {
  background-color: #00e639 !important;
  padding: 13px !important;
  box-shadow: inset 0px 11px 8px -10px #ccc !important;
}

.ListItemIcon.ClosedItem {
  margin: 0 auto !important;
}

.Text {
  color: #002f60;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif !important;
  margin-top: 1px !important;
}

.Text+svg {
  font-size: 20px !important;
  color: #002f60 !important;
}

.SelectedText,
.OpenItem:hover .UnselectedText {
  color: #0051a8;
}

.UnselectedText {
  color: #002f60;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted rgba(138, 138, 138, 0.5);
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: rgba(122, 122, 122, 0.5) !important;
  color: #4b0079 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  left: -15px;
  top: -24px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltippanel {
  background-color: #4b0079;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 30px;
}

.tooltip label {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.allDocumentsWrap {
  text-align: center;
  margin-bottom: 15px;
}