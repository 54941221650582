.paper {
    margin-bottom: 20px;
    padding: 28px 25px 17px 25px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
      0 0 2px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 20px !important;
    border: 1px solid #4b0079;
  }
  
  .ContinueButton {
    background-color: white !important;
    border: solid 2px #4b0079 !important;
    border-radius: 12px !important;
    margin-top: 100px;
    color: #4b0079 !important;
  }
  
  .Margin {
    margin: 4px;
  }
  /*Switch*/
  
  .PiiDataSwitch,
  .PiiDataSwitchCheck {
    width: 100px !important;
    padding: 4px !important;
    border: 1px solid #4b0079;
    border-radius: 20px;
    z-index: 2 !important;
    height: 39px !important;
  }
  
  .PiiDataSwitch > span,
  .PiiDataSwitchCheck > span {
    padding: 4px;
  }
  
  .PiiDataSwitch > span {
    transform: translateX(40px) !important;
  }
  
  .PiiDataSwitch > span + span,
  .PiiDataSwitchCheck > span + span {
    border-radius: 20px;
    background-color: transparent !important;
  }
  
  .PiiDataSwitch > span > span > span,
  .PiiDataSwitchCheck > span > span > span {
    width: 48px;
    background-color: #4b0079;
    height: 28px;
    border-radius: 25px;
  }
  
  .PiiDataSwitchLabels {
    position: absolute;
    width: 116px;
    left: 0;
    top: 0;
  }
  
  .pgswitch {
    position: relative;
    margin: 7px 0px;
  }
  
  .PiiDataSwitchLabels > span {
    width: 48px;
    justify-content: center;
    font-weight: 500;
    display: inline-flex;
    padding: 12px 0px;
  }
  
  .PiiDataSwitchCheck + span .PiiDataSwitchNo,
  .PiiDataSwitch + span .PiiDataSwitchYes {
    color: #fff;
  }
  
  .PiiDataSwitchCheck + span .PiiDataSwitchYes,
  .PiiDataSwitch + span .PiiDataSwitchNo {
    color: #4b0079;
  }
  
  .PiiDataSwitchCheck > span > span > span::before {
    content: "No";
    color: #fff !important;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    padding: 6px 0px;
    justify-content: center;
  }
  
  .PiiDataSwitch > span > span > span::before {
    content: "YES";
    color: #fff !important;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    padding: 5px 0px;
    justify-content: center;
  }
  
  .FooterActions button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .prevBtn {
    display: inline-block !important;
  }
  
  .cancelButton {
    display: inline-block !important;
  }
  
  .FormHelperText {
    color: #e53935 !important;
    padding: 0px 0px 10px 0px !important;
    display: block !important;
  }
  
  .errormsg {
    display: inline-block !important;
    width: 100% !important;
  }
  
  .DialogBoxTitle {
    text-align: center;
    margin: 0px;
    padding: 16px !important;
    border-bottom: 1px solid #4b0079 !important;
    box-shadow: 0px 3px rgba(0, 0, 0, 0.02);
  }
  
  .DialogBoxTitle h1 {
    margin: 0px !important;
  }
  
  .DialogBoxContext p {
    color: #707070;
  }
  
  .DialogBoxContext {
    padding: 16px !important;
  }
  
  .OtherOfficeWrap legend {
    font-size: 14px;
  }
  
  .MainOfficeDialogboxPaper {
    height: 215px !important;
    border: 1px solid #4b0079 !important;
    border-radius: 20px !important;
    box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  }
  
  .MainOfficeDialogboxContainer {
    max-width: 80%;
    min-width: 50% !important;
    margin: 0 auto;
  }
  
  .reportListText > span {
    color: #707070;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
  }
  