.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
  position: relative;
}

.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: flex-end;
}

.FilterInput {
  width: 160px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.FilterSearchButton {
  margin-right: 10px;
  margin-bottom: 10px;
}

.FilterResetButton {
  margin-bottom: 10px;
}

.FilterAddWrap {
  float: right;
  margin-top: 20px;
  text-align: right;
}

.FilterResetButton button {
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}

.FilterResetButton button span {
  font-weight: 600 !important;
}

.ButtonGroup1 {
  display: inline-block !important;
}

.ButtonGroup2 {
  display: inline-block !important;
  margin-bottom: 10px;
}

th:first-child {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

th:last-child {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

th {
  padding: 8px !important;
  border-bottom: none !important;
  line-height: 1.5 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #ccc;
}

td {
  padding: 8px !important;
  line-height: 1.846 !important;
  color: #707070 !important;
  font-size: 12px !important;
  font-weight: normal !important;
}

table > thead {
  background-color: transparent !important;
}

.ScrollTable > div {
  max-height: calc(100vh - 345px) !important;
  overflow-x: auto !important;
}

td p {
  margin: 0px !important;
}

td button:hover {
  background-color: transparent !important;
}
td button {
  padding: 0px 10px !important;
  color: #4b0079 !important;
  width: 36px;
}
.EditIcon {
  height: 18px !important;
  width: 18px !important;
}

.MainOfficeDialogboxPaper {
  height: 215px !important;
  border: 1px solid #4b0079 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  padding: 20px;
}

.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}

.BackToButton {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #4b0079 !important;
  outline: 0 !important;
  text-transform: unset !important;
  padding-right: 10px !important;
  margin-bottom: 3px;
}

.BackToButton img {
  width: 14px !important;
  margin-right: 3px;
}
table tfoot tr td {
  width: 100% !important;
  display: flex !important;
}

.iconStyle {
  display: flex;
}

.ScrollTable svg {
  fill: #4b0079 !important;
}

.DialogBoxTitle {
  text-align: center;
  border-bottom: 1px solid #4b0079 !important;
  padding: 5px 24px;
}

.MainOfficeMailDialogboxPaper {
  height: auto !important;
  width: 450px !important;
  border: 1px solid #4b0079 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  padding: 20px;
}

.DialogBoxTitle h1 {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}


.MuiGridItem {
  align-self: center;
  text-align: center;
  margin: 0px auto !important;
}

.ReactInput1{
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.DialogBoxContext .AlertBoxContent{
  margin:18px !important;
}

.DialogBoxContext p {
  color: #707070;
}

.CreditMainOfficeDialogboxPaper .DialogBoxTitle {
  display: none;
}
.CreditMainOfficeDialogboxPaper .DialogBoxContext {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CreditMainOfficeDialogboxPaper{
  margin-top: 66px !important;
  border-bottom: 1px solid #4b0079 !important;
  width: auto !important;
  max-width: 800px !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  height: 185px !important;
}
