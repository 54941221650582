.root{
  padding: 0;
}
.ReactStepper {
  padding: 0px !important;
  background-color: transparent !important;
  flex-wrap: wrap;
}
.ReactStep {
  padding: 0px 15px 0px 0px  !important;
  width: auto !important;
  margin: 0px 6px 0px 0px;
  display: inline-flex;
  flex-wrap: wrap;
  flex: none !important;
}

.ReactStep button:disabled{
  color: #707070;
  font-weight: normal !important;
  opacity: 0.65;
}
.ReactStep button span {
  font-size: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  .ReactStep {
    padding: 0px 10px 0px 10px  !important;
    margin: 0px 5px 0px 5px;
  }
  .ReactStepper{
    margin-bottom: 15px;
  }
}