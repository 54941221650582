.TabOptions button {
  border: 1px solid #4b0079 !important;
  border-radius: 10px !important;
  margin-right: 4px !important;
  color: #707070 !important;
  font-weight: 600 !important;
  background-color: white !important;
  padding: 10px !important;
  margin-top: 12px;
  margin-bottom: 12px;
}

.TabOptionsActive > span {
  background-color: #4b0079 !important;
}

.TabOptionsInactive {
  background-color: #fcfdfd !important;
  margin: 1px;
}

.para {
  color: white;
  z-index: 1;
  margin: 1px;
}
