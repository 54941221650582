.paper {
    padding: 15px;
    background-color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
    border-radius: 20px !important;
    border: 1px solid #4b0079;
    position: relative;
  }
  
  .FilterWrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    align-items: flex-end;
    padding: 0px 0px 5px 0px;
    width: auto;
  }
  
  .FilterInput {
    margin-bottom: 15px;
    text-align: right;
  }
  
  .FilterSearchButton {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .FilterResetButton {
    margin-bottom: 10px;
  }
  
  .FilterAddWrap {
    float: right;
    margin-top: 0px;
    text-align: right !important;
  }
  
  .FilterResetButton button {
    background-color: transparent !important;
    border: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    box-shadow: none !important;
  }
  
  .FilterResetButton button span {
    font-weight: 600 !important;
  }
  .BackToButton {
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: normal !important;
    color: #4b0079 !important;
    outline: 0 !important;
    text-transform: unset !important;
    padding-right: 10px !important;
    margin-bottom: 3px;
  }
  
  .BackToButton img {
    width: 14px !important;
    margin-right: 3px;
  }
  .ButtonGroup2 {
    display: inline-block !important;
    margin-bottom: 10px;
  }
  .FilterWrap1 {
    margin: 6px 5px 0px 15px;
    padding: 1px;
  }
  .FilterWrap2 {
    margin: 6px 5px 0px 5px;
    padding: 1px;
  }
  
  th:first-child {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
  }
  
  th:last-child {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  }
  
  th {
    padding: 8px !important;
    border-bottom: none !important;
    line-height: 1.5 !important;
    font-size: 12px !important;
    color: #4b0079 !important;
    font-weight: 700 !important;
    background-color: #fcfaff !important;
    vertical-align: middle !important;
    text-align: left;
  }
  
  table > thead {
    background-color: transparent !important;
  }
  
  .ScrollTable > div {
    max-height: calc(100vh - 266px) !important;
    overflow-x: auto !important;
  }
  
  .ScrollTable svg {
    fill: #4b0079 !important;
  }
  
  td button {
    padding: 0px 10px !important;
    color: #4b0079 !important;
    border: 0px !important;
    box-shadow: none !important;
  }
  
  td {
    padding: 8px !important;
    line-height: 1.846 !important;
    color: #707070 !important;
    font-size: 12px !important;
    font-weight: normal !important;
    vertical-align: middle;
  }
  
  tfoot {
    border-top: 1px solid #eee;
  }
  
  .EditIcon {
    height: 18px !important;
    width: 18px !important;
  }
  
  .CCLogoImg {
    display: block;
    max-width: 83px;
    max-height: 83px;
    margin-bottom: 7px;
  }
  .CCLogoImg img {
    width: 100%;
  }
  
  .iconStyle {
    display: flex;
  }
  
  tr {
    height: unset !important;
  }
  
  table tr td {
    border-bottom: 1px solid #eee;
  }
  
  table>thead {
    background-color: transparent !important;
  }

  .MainOfficeDialogboxPaper {
    height: 215px !important;
    border: 1px solid #4b0079 !important;
    border-radius: 20px !important;
    box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
    padding: 20px;
  }
  
  .MainOfficeDialogboxContainer {
    max-width: 80%;
    min-width: 50% !important;
    margin: 0 auto;
  } 