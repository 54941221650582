.paper {
  margin-bottom: 20px;
  padding: 35px;
  background-color: #fff;
  -webkit-box-shadow: none !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 100px;
  flex-direction: column;
}
.logoutPage {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.CCLogoImg {
  display: block;
  margin-bottom: 20px;
}
.CCLogoImg img {
  width: 170px;
  height: auto;
}
