.Container {
  width: 100%;
  height: 100%;
}

.Login {
  display: flex;
  align-items: center;
  flex-direction: column;
  vertical-align: middle;
  min-height: 625px;
  height: 100vh;
  background-color: #fef9f5;
}

.LoginTitle {
  color: white;
  display: flex;
  justify-content: flex-start;
  width: 400px;
  margin-left: 60px;
}

.Margin {
  margin: 10px;
  width: 100%;
}

.BottomMargin {
  margin-bottom: 20px;
}

.Errors {
  color: red;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.Box {
  margin-top: 10vh;
  width: 308px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-color: #4b0079;
  border-style: solid;
  border-width: 1px;
  max-width: 350px !important;
  padding: 20px !important;
  background-color: #fff;
}

.InputField {
  border-color: #4b0079 !important;
  border-radius: 12px !important;
  border-width: 1px !important;
  height: auto;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.FormLabel {
  color: #93a29b !important;
}

.ContinueButton {
  background-color: #4b0079 !important;
  border: solid 1px #4b0079 !important;
  width: 100%;
  border-radius: 20px !important;
  margin-top: 0px;
  color: #fff !important;
  font-weight: 600 !important;
  padding: 3px 14px !important;
  font-size: 14px !important;
  line-height: 1.846 !important;

}

.cyberComplianceLogo {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #002F60;
}

.OrText {
  color: #93a29b;
  font-weight: bolder;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
}

.Or {
  display: flex;
  flex-direction: row;
}

.FormHelperText {
  color: red !important;
  margin-top: 2px !important;
  line-height: 1.2 !important;
  font-size: 14px !important;
}

.Login a {
  color: #4b0079;
  text-decoration: none;
  font-size: 16px;
  font-weight: bolder;
}

.Login a:visited {
  color: #4b0079;
}

.Login hr {
  margin-top: 32px;
  margin-left: 18px;
  margin-right: 18px;
  height: 1px;
  width: 142px;
  border-color: #eaedec;
  background-color: #eaedec;
  color: #eaedec;
  border-style: solid;
}

.TextField {
  border-color: #4b0079 !important;
  border-radius: 12px !important;
  border-width: 1px !important;
  width: 100%;
  height: auto;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: auto !important;
}

.PasswordField input {
  padding: 5px 7px !important;
  border-radius: 10px !important;
  line-height: 1.846 !important;
  height: 37px !important;
}

.RALogoImg {
  display: block;
  margin-bottom: 7px;
}

.RALogoImg img {
  width: 130px;
  height: auto;
}