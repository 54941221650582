.EditIcon {
  height: 18px;
  width: 18px;
}

.button {
  background-color: white;
  border: white;
  cursor: pointer;
}

.linkButton{
  background: #4b0079 !important;
  width: auto;
  color: white !important;
  padding: 5px !important;
  margin: 5px !important;
  border-radius: 10px;
}

.linkButton:hover{
  background-color: #4b0079 !important;
}

