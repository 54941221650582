
.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
}

.FilterAddWrap {
  float: right;
  margin-top: 20px;
  text-align: right;
}

.topstepperButons {
  margin-left: 0px !important;
  display: flex !important;
  flex-direction: row;
}

.topstepperButons button {
  border: 0px !important;
padding: 3px 0px;
margin: 0;
}

.FilterInput {
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.prevBtn {
  display: inline-flex !important;
  margin: 0px 10px 15px 0px;
  min-width: 114px;
  height: 31px;
  align-items: center;
}
.nextBtn{
  display: inline-flex !important;
  margin: 0px 10px 15px 0px;
  height: 31px;
  align-items: center;
  min-width: 83px;
  margin-left: 10px;
  display: inline-block;
}
.cancelButton {
  margin-left: 10px !important;
  display: inline-block !important;
}

.salText {
  font-weight: 600;
  margin-top: 5%;
}

.sal_levels {
  background-color: #d3d7dc;
  font-weight: 600;
  border-radius: 5%;
  padding: 10px 15px !important;
  display: inline-block;
  margin-top: 15px !important;
  margin-right: 15px !important;
  font-size: 12px;
}

.selected_level {
  background-color: white;
  border-radius: 5%;
  margin-top: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
}

.TabOptionsActive > span {
  color: #4b0079 !important;
}

.TabOptionsInactive {
  background-color: #fcfdfd !important;
}

.TabOptions button {
  border: 1px solid #4b0079;
  border-radius: 10px;
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
  background-color: white;
  padding: 0.5em 1em;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px;
  color: #4b0079;
}

.CompanyFormHead {
  font-weight: 500;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 5px;
  display: block;
}

.secure_para {
  font-weight: 400;
  font-size: 12px;
}

.Security_Assurance_Grid {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
}

.Tabinner {
  padding: 15px 20px;
  border: 1px solid #4b0079 !important;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 20px;
  border-top-left-radius: 0;
}

.cancelButton button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: transparent !important;
  color: #4b0079 !important;
  box-shadow: none !important;
  border: 0px !important;
}

.crossBtn {
  margin-right: 10px !important;
  margin-bottom: 20px !important;
  min-width: 0px !important;
  float: right !important;
  position: relative;
}

.crossBtn button{
  min-width: 0px !important;
}

.CompanySelect {
  color: unset !important;
}


.crossBtn .cancleTooltip {
  visibility: hidden;
  width: 60px;
  color: #4b0079 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  left: -10px;
  top: -20px;
}

.crossBtn:hover .cancleTooltip {
  visibility: visible;
}

.RelativeAssessment {
  width: calc(50% - 8px);
} 
.LevelHead h3 {
  font-weight: 500;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.FooterActions {
  display: flex;
  justify-content: space-between;
}
.topstepperButonsWrap {
  display: flex;
}

.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}

.DialogBoxTitle {
  text-align: center;
  margin: 0px;
  padding: 16px !important;
  border-bottom: 1px solid #4b0079 !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02);
}

.DialogBoxTitle h1 {
  margin: 0px !important;
}

.CreditMainOfficeDialogboxPaper .DialogBoxTitle {
  display: none;
}
.DialogBoxContext {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px !important;
}
.DialogBoxContext p {
  color: #707070;
}


@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  .nextBtn{
    margin: 0px 10px 15px 10px;
  }

}