.paper {
  padding: 35px;
  background-color: #fff;
  -webkit-box-shadow: none !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
  position: relative;
}

.prevBtn {
  display: inline-flex !important;
  margin: 0px 10px 5px 0px;
  min-width: 113px;
  height: 31px;
  align-items: center;
}


.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: flex-end;
}

.FilterInput {
  margin-bottom: 15px;
  text-align: right;
}

.FilterSearchButton {
  margin-right: 10px;
  margin-bottom: 10px;
}

.FilterResetButton {
  margin-bottom: 10px;
}

.FilterAddWrap {
  float: right;
  margin-top: 20px;
}

.FilterResetButton button {
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}

.FilterResetButton button span {
  font-weight: 600 !important;
}

th:first-child {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

th:last-child {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

th {
  padding: 8px !important;
  border-bottom: none !important;
  line-height: 1.5 !important;
  font-size: 12px !important;
  color: #4b0079 !important;
  font-weight: 700 !important;
  background-color: #fcfaff !important;
  vertical-align: middle !important;
}

table > thead {
  background-color: transparent !important;
}

.ScrollTable > div {
  max-height: calc(100vh - 266px) !important;
  overflow-x: auto !important;
}

.ScrollTable svg {
  fill: #4b0079 !important;
}

td button {
  padding: 0px 10px !important;
  color: #4b0079 !important;
}

td {
  padding: 8px !important;
  line-height: 1.846 !important;
  color: #707070 !important;
  font-size: 12px !important;
  font-weight: normal !important;
  vertical-align: middle;
}

tfoot {
  border-top: 1px solid #eee;
}

.EditIcon {
  height: 18px !important;
  width: 18px !important;
}

.CCLogoImg {
  display: block;
  max-width: 83px;
  max-height: 83px;
  margin-bottom: 7px;
}
.CCLogoImg img {
  width: 100%;
}

.iconStyle {
  display: flex;
}

tr {
  height: unset !important;
}

table tr td {
  border-bottom: 1px solid #eee;
}

table>thead {
  background-color: transparent !important;
}
.content{
  padding: 8px !important;
  line-height: 1.846 !important;
  color: #707070 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  vertical-align: middle;
}
.header{
  padding: 8px !important;
  border-bottom: none !important;
  line-height: 1.5 !important;
  font-size: 24px !important;
  color: #4b0079 !important;
  font-weight: 700 !important;
  background-color: #fcfaff !important;
  vertical-align: middle !important;
}
ul.a {
  list-style-type: circle;
}
.image{
  width:95%;
}
.imageWidth{
  max-width: 80%;
}

.TextCenter{
  text-align: center;
}

.TutorialHeaders h5 {
  margin-right: 15px;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.TutorialHeaders{
  display: flex;
  align-items: center;
  margin-bottom: 23px !important;
  padding-bottom: 10.5px !important;
}