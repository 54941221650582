.tableStyle td:nth-child(1) {
  width: 30% !important;
}

.tableStyle td:nth-child(3) {
  width: 20% !important;
}
.tableStyle th {
  width: 40% !important;
}

.tableStyle th,
.tableStyle td {
  border: 1px solid #ccc;
}

.tableStyle th,
.tableStyle td {
  border-radius: 0px !important;
}

.tableStyle td {
  width: 100%;
}

.securityPlanTable .tableStyle td:nth-child(3) {
  width: 30% !important;
}
.securityPlanTable .tableStyle td:nth-child(2) {
  width: 50% !important;
}
.securityPlanTable .tableStyle {
  width: 100%;
}
.securityPlanTable .tableStyle td *{
  font-size: 12px;
  color: #707070;
}
.red-score {
  background-color: #dc3545;
  border: 1px solid #dc3545;
  color: #fff;
}

.yellow-score {
  background-color: #ffc107;
  border: 1px solid #ffc107;
  color: #000;
}

.green-score {
  background-color: #28a745;
  border: 1px solid #28a745;
  color: #fff;
}

.light-gray-score {
  background-color: #d3d3d3;
  border: 1px solid #d3d3d3;
  color: #000;
}

.default-score {
  background-color: #6c757d;
  border: 1px solid #6c757d;
  color: #fff;
}
.QuestionViewMIL {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 30px 0px 15px 0px;
}