.paper {
  margin-bottom: 20px;
  padding: 28px 25px 17px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
  position: relative;
}

.CompanySelect {
  color: unset !important;
}

.prevBtn {
  margin-left: 20px;
}

.FooterActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 220px;
  flex-basis: unset !important;
}

.FooterActions button {
  margin-left: 10px;
}

.pageHeaderContainer {
  justify-content: space-between;
  margin-bottom: 15px;
}

.pageHeader {
  width: calc(100% - 220px);
  flex-basis: unset;
  max-width: calc(100% - 220px);
  flex-direction: row;
  display: inline-flex;
  align-items: center;
}

.topstepperButons {
  margin-left: 10px !important;
  display: inline-block !important;
}

.topstepperButons button {
  border: 0px !important;
  padding: 27px 16px;
}

.crossBtn .cancleTooltip {
  visibility: hidden;
  width: 60px;
  color: #4b0079 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  left: -10px;
  top: -20px;
}

.crossBtn:hover .cancleTooltip {
  visibility: visible;
}

.crossBtn button {
  height: 35px !important;
}

.CompanyFormHead {
  font-weight: 500;
  font-size: 16px;
  display: block;
  padding-left: 0px;
}

.FilterWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: flex-end;
}

.FilterInput {
  width: 160px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.FilterSearchButton {
  margin-right: 10px;
  margin-bottom: 10px;
}

.FilterResetButton {
  margin-bottom: 10px;
}

.FilterAddWrap {
  float: right;
  margin-top: 20px;
  text-align: right;
}

.FilterResetButton button {
  background-color: transparent !important;
  border: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
}

.FilterResetButton button span {
  font-weight: 600 !important;
}

.ButtonGroup1 {
  display: inline-block !important;
}

.ButtonGroup2 {
  display: inline-block !important;
  margin-bottom: 10px;
}

th:first-child {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

th:last-child {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

th {
  padding: 8px !important;
  border-bottom: none !important;
  line-height: 1.5 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #ccc;
}

td {
  padding: 8px !important;
  line-height: 1.846 !important;
  color: #707070 !important;
  font-size: 12px !important;
  font-weight: normal !important;
}

table > thead {
  background-color: transparent !important;
}

.ScrollTable > div {
  max-height: calc(100vh - 345px) !important;
  overflow-x: auto !important;
}

td p {
  margin: 0px !important;
}

td button:hover {
  background-color: transparent !important;
}

td button {
  padding: 0px 10px !important;
  color: #4b0079 !important;
  width: 36px;
}

.EditIcon {
  height: 18px !important;
  width: 18px !important;
}

.MainOfficeDialogboxPaper {
  height: auto !important;
  border: 1px solid #4b0079 !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  padding: 20px;
  max-height: 80vh !important;
  max-width: 60vw !important;
}

.MainOfficeDialogboxContainer {
  max-width: 80%;
  min-width: 50% !important;
  margin: 0 auto;
}

.BackToButton {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #4b0079 !important;
  outline: 0 !important;
  text-transform: unset !important;
  padding-right: 10px !important;
  margin-bottom: 3px;
}

.BackToButton img {
  width: 14px !important;
  margin-right: 3px;
}

table tfoot tr td {
  width: 100% !important;
  display: flex !important;
}

.iconStyle {
  display: flex;
}

.ScrollTable svg {
  fill: #4b0079 !important;
}

.assessmentTypeWrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4b0079;
  background-color: #fff;
  width: 100%;
}

.assessmentTypeWrap > label {
  padding: 6px 30px;
  width: 80px;
  margin-right: 0px;
}

.assessmentTypeContent {
  width: calc(100% - 410px);
  padding: 15px 30px 15px 0px;
}

.assessmentTypecontainer {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
  border-radius: 20px !important;
  border: 1px solid #4b0079;
}

.assessmentTypecontainer .assessmentTypeWrap:last-child {
  border-bottom: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.assessmentTypecontainer .assessmentTypeWrap:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.assessmentTypeImg {
  width: 150px;
  max-height: 150px;
  padding: 15px 30px 15px 0px;
  text-align: center;
}

.assessmentTypeLabel {
  width: 180px;
  max-height: 150px;
  padding: 15px 30px 15px 0px;
  text-align: center;
}

.assessmentTypeImg img {
  max-width: 100%;
  max-height: 100px;
}

.header {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 7px;
}

.SubHeader {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
}
.PricingMainOfficeDialogboxPaper {
  margin-top: 66px !important;
  border-bottom: 1px solid #4b0079 !important;
  width: auto !important;
  max-width: 800px !important;
  border-radius: 20px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.02) !important;
  padding: 20px;
  height: 345px !important;
  width: 420px !important;
}


.PricingMainOfficeDialogboxPaper tbody tr td:nth-child(2),
.PricingMainOfficeDialogboxPaper tbody tr td:nth-child(3) {
  text-align: center;
}
.DialogBoxTitle {
  text-align: center;
  border-bottom: 1px solid #4b0079 !important;
  padding: 16px 24px;
}
.DialogBoxTitle h1 {
  margin-bottom: 0px !important;
}
.DialogBoxContext {
  padding: 16px !important;
}
.viewDetailsDialogBoxContext {
  padding: 16px !important;
  max-height: 100% !important;
}
.viewDetailsDialogBoxContext::after {
  box-sizing: content-box !important;
}
.viewDetailsDialogBoxContext::before {
  box-sizing: content-box !important;
}

.spanStyle {
  padding-top: 10px;
}
.spanStyle div {
  color: #707070;
}
.cardContent > p {
  font-weight: 500;
}
.MuiInputAdornmentPositionStart {
  margin-right: 0px !important;
}
.MuiOutlinedInputAdornedEnd {
  padding-right: 0px !important;
}

.gridItem {
  margin: 20px 0px 0px 5px !important;
  height: 45px !important;
  font-weight: 200px !important;
  padding-bottom: 0px;
}
.cardActions {
  background-color: #4b0079;
  position: absolute;
  width: 100%;
  bottom: 0px;
  visibility: hidden;
}
.cardActions svg {
  color: #fff !important;
}
.cardActions button {
  background-color: #4b0079 !important;
  margin-left: 5px;
}

.card:hover .cardActions {
  visibility: visible;
}
.cardWrap {
  cursor: pointer;
  border-radius: 20px !important;
  border: 1px solid rgb(75, 0, 121);
  height: 200px;
  position: relative;
}
.cardWrap:hover {
  z-index: 1000;
  box-shadow: 0 0 6px #0003;
  transform: scale(1.1);
}
.cardWrap.rowFirstCard:hover {
  margin-left: 20px;
}
.cardWrap.rowLastCard:hover {
  margin-right: 20px;
}

.tinytext {
  font-size: 8px;
}
.row.header div {
  font-size: 7.5px;
  padding: 0.1rem;
}
.assessmentTypeSearchWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.assessmentTypeSearchWrap .crossBtn {
  margin-left: 10px;
}

.selectedTab {
  color: #330352 !important;
  font-weight: bolder !important;
}
.muiTabsFlexContainer button {
  font-size: 0.97rem !important;
  color: #4c305e !important;
  font-weight: bold !important;
}

.batchStyle {
  color: #ffffff !important;
  background-color: #4b0079 !important;
}
